import React from "react"
import CTA from "../components/cta"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"

import Sertifika1 from "../images/sertifika-izmir-dokuz-eylul-sergisi.jpg"
import Sertifika2 from "../images/sertifika-izmir-enternasyonal-fuari-1936.jpg"
import Sertifika3 from "../images/sertifika-izmir-enternasyonal-fuari-1940-1.jpg"
import Havlu from "../images/havlu-mrc.jpg"
import Yarn from "../images/yarn-mrc.jpg"
import Background1 from "../images/bg-12.jpg"

const Hakkimizda = () => (
  <Layout>
    <SEO title="Hakkımızda" />

    <PageHeader title="Hakkımızda" />

    <div className="h-100 pb-4">
      <div className="main pb-2 w-full text-gray-700">
        <article className="mb-5 grid grid-cols-1 md:grid-cols-3">
          <div
            className="col-span-1 md:col-span-3 text-lg  text-center bg-center object-center"
            style={{ backgroundImage: `url(` + Background1 + `)` }}
          >
            <div className="w-full  bg-white bg-opacity-75 py-24 px-5">
              <p className="text-4xl">
                Her şey <span className="text-red-500">1842</span>’de yerli malı
                iplik ticareti ile başladı.
              </p>

              <p className="text-2xl text-gray-600 my-5">
                <span className="font-bold">6 kuşaktır</span> ticaret
                serüvenimiz aynı heyecan ve disiplinle devam ediyor
              </p>
            </div>
          </div>

          <div className="col-span-3 text-center text-2xl pt-10 pb-5">
            1929, 1936 ve 1940 Yıllarında Katıldığımız Fuar Sertifikalarımız
          </div>

          <div className="col-span-3 flex items-start justify-center flex-wrap pb-10">
            <div class="max-w-xs  rounded overflow-hidden shadow-lg m-3">
              <img
                src={Sertifika1}
                className="w-full h-64 object-cover"
                alt="izmir dokuz eylül sergisi"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">
                  İzmir Dokuz Eylül Sergisi, 1929
                </div>
              </div>
            </div>

            <div class="max-w-xs rounded overflow-hidden shadow-lg m-3">
              <img
                src={Sertifika2}
                className="w-full"
                alt="izmir dokuz eylül sergisi"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">
                  İzmir Enternasyonal Fuarı, 1936
                </div>
              </div>
            </div>

            <div class="max-w-xs rounded overflow-hidden shadow-lg m-3">
              <img
                src={Sertifika3}
                className="w-full"
                alt="izmir dokuz eylül sergisi"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">
                  İzmir Enternasyonal Fuarı,1940
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-3 md:col-span-1 text-center  m-0">
            <img
              src={Havlu}
              alt="havlu dokuma üretimi denizli"
              className="object-cover w-full object-bottom"
              style={{ height: "300px" }}
            />
          </div>

          <div className="col-span-3 md:col-span-2 text-center py-16 px-10 bg-secondary-100">
            <h2 className="text-2xl my-10 font-bold" id="dokuma">
              Havlu Dokuma
            </h2>
            <p className="text-lg">
              1995 yılından beri havlu kumaş dokuma alanında faaliyet
              göstermekteyiz. 6 jakarlı ve 3 armürlü tezgahımız ile
              müşterilerimize hızlı ve özenli hizmet vermekteyiz.
            </p>
          </div>

          <div className="col-span-3 md:col-span-2 text-center py-16 px-10">
            <h2 className="text-2xl my-10 font-bold" id="iplik">
              İplik Ticareti
            </h2>
            <p className="text-lg">
              2011 yılından itibaren Maraş İSKUR firmasının DENİZLİ bayiliğini
              yapıyoruz.
            </p>
            <p className="text-lg">
              İSKUR’un ürettiği iplik ve örme kumaş ürünlerini Denizli’de iplik
              ihtiyacı olan firmalara temin ediyoruz.
            </p>
          </div>
          <div className="col-span-3 md:col-span-1 text-center  m-0">
            <img
              src={Yarn}
              alt="yarn trade denizli"
              className="object-cover w-full object-center"
              style={{ height: "400px" }}
            />
          </div>
          <div className="col-span-3 pt-10 px-5 md:px-40">
            <CTA
              title="MRC Tekstil"
              message="Sipariş ve sorularınız için bize  ulaşın"
              buttonText="Bize Ulaşın"
              buttonLink="/iletisim/"
            />
          </div>
        </article>
      </div>
    </div>
  </Layout>
)

export default Hakkimizda
